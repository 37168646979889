import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertError, AlertSuccess } from '../../common/components/Alert';
import URLS from '../../urls';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { AppContext } from '../../context/global';
import SpinnerIcon from '../../common/components/SpinnerIcon';

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkout: props.checkout,
      isWaitingCreditCard: false,
    };
    this.checkCreditCardPaymentStatus =
      this.checkCreditCardPaymentStatus.bind(this);
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    if (
      this.state.checkout.type === 'credit_card' &&
      this.state.checkout.is_waiting
    ) {
      this.checkCreditCardPaymentStatus();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  checkCreditCardPaymentStatus() {
    if (!this._mounted) {
      return;
    }

    this.setState((state) => ({
      ...state,
      isWaitingCreditCard: true,
    }));
    const url = `${Config.apiHost}checkouts/${this.props.checkout.id}/`;
    Ajax.get(url).done((checkout) => {
      if (checkout.is_waiting) {
        setTimeout(this.checkCreditCardPaymentStatus, 2000);
      } else {
        this.setState((state) => ({
          ...state,
          checkout,
          isWaitingCreditCard: false,
        }));
      }
    });
  }

  render() {
    const { i18n } = this.props;
    return (
      <div>
        {this.state.checkout.is_canceled && (
          <AlertError>
            {i18n.t('Seu pagamento foi cancelado pela operadora.')}
          </AlertError>
        )}
        {!this.state.checkout.is_canceled && (
          <AlertSuccess>
            {i18n.t('Recebemos seu pagamento para o plano')}{' '}
            {this.props.plan.name}
            {this.state.isWaitingCreditCard
              ? ' ' +
                i18n.t(
                  'e estamos aguardando a confirmação por parte da operadora do cartão.'
                ) +
                '.'
              : '.'}
          </AlertSuccess>
        )}
        <table className="table">
          <tbody>
            <tr>
              <td width="20%">
                <b>{i18n.t('Valor pago')}</b>
              </td>
              <td>R${this.state.checkout.total}</td>
            </tr>
            <tr>
              <td width="20%">
                <b>{i18n.t('Situação')}</b>
              </td>
              <td>
                {this.state.isWaitingCreditCard && (
                  <b className="text-info">
                    <SpinnerIcon /> {this.state.checkout.status_name}...
                  </b>
                )}
                {!this.state.isWaitingCreditCard &&
                  this.state.checkout.status_name}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                {this.state.isWaitingCreditCard && (
                  <>
                    <p>
                      <b>{i18n.t('Obs')}.:</b>{' '}
                      {i18n.t(
                        'Aguarde a confirmação do seu pagamento antes de realizar outra operação.'
                      )}{' '}
                      {i18n.t(
                        'Algumas vezes a operadora do cartão não confirma o pagamento imediatamente, levando alguns segundos para fazê-lo.'
                      )}{' '}
                    </p>
                    <p>
                      {i18n.t(
                        'Enquanto aguarda, você pode continuar utilizando o FastFormat normalmente. Um e-mail será enviado quando o pagamento for confirmado.'
                      )}
                    </p>
                  </>
                )}
                <p>
                  {i18n.t(
                    'Você pode consultar a situação dos seus pagamento em'
                  )}{' '}
                  <Link to={URLS.billing}>
                    {i18n.t('Meus pagamentos e vouchers')}
                  </Link>
                </p>
              </td>
            </tr>
            {this.state.checkout.voucher_amount &&
              this.state.checkout.voucher_amount > 1 && (
                <tr>
                  <td>
                    <b>
                      {i18n.t('Vouchers')}/{i18n.t('Licenças')}
                    </b>
                  </td>
                  <td>
                    {i18n.t(
                      'Para consultar os vouchers das licenças adquiridas, acesse'
                    )}{' '}
                    <Link to={URLS.billing}>
                      {i18n.t('Meus pagamentos e vouchers')}
                    </Link>
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {this.state.checkout.type === 'bank_slip' && (
          <a
            href={this.state.checkout.payment_url}
            target="_blank"
            className="btn btn-primary btn-lg"
          >
            {i18n.t('Imprimir boleto')}
          </a>
        )}
      </div>
    );
  }
}

PaymentConfirmation.propTypes = {
  plan: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired,
};

PaymentConfirmation.contextType = AppContext;

export default withNamespaces()(PaymentConfirmation);
