import React from 'react';
import PropTypes from 'prop-types';

export default class SpinnerIcon extends React.Component {
    render() {
        const {size} = this.props;
        return <i className={`fas fa-spinner fa-pulse fa-${size}x fa-fw`}/>;
    }
}

SpinnerIcon.propTypes = {
    size: PropTypes.oneOf([1, 2, 3]),
};

SpinnerIcon.defaultProps = {
    size: 1
};