import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Ajax from '../../common/ajax';
import { AlertError } from '../../common/components/Alert';
import Col from '../../common/containers/Col';
import Content from '../../common/containers/Content';
import Panel from '../../common/containers/Panel';
import PrivatePage from '../../common/containers/PrivatePage';
import Row from '../../common/containers/Row';
import Config from '../../config';
import URLS from '../../urls';
import PaymentConfirmation from './PaymentConfirmation';

class PaymentConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkout: null,
      processing: true,
    };
  }

  componentDidMount() {
    this.fetchCheckout();
  }

  fetchCheckout() {
    const url = `${Config.apiHost}checkouts/last_checkout/`;
    Ajax.get(url)
      .done((checkout) => {
        this.setState((state) => ({
          ...state,
          checkout,
        }));
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      });
  }

  render() {
    const { i18n } = this.props;
    const { processing, checkout } = this.state;

    return (
      <PrivatePage
        title={i18n.t('Pagamento')}
        hasAside={false}
        isProcessing={processing}
      >
        <Content>
          <Row>
            <Col md={8} mdOffset={2}>
              {checkout && (
                <Panel>
                  <PaymentConfirmation
                    checkout={checkout}
                    plan={{ name: checkout.plan_name }}
                  />
                </Panel>
              )}

              {!processing && !checkout && (
                <AlertError>
                  {i18n.t('Nenhum pagamento foi encontrado.')}
                </AlertError>
              )}
            </Col>
          </Row>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(PaymentConfirmationPage);
